import { createStyles, makeStyles } from '@material-ui/core/styles';

const useAutomationsStyles = makeStyles(() =>
  createStyles({
    header: {
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '14px',
      marginBottom: 16
    },
    countHeader: {
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '24px',
      color: '#2864AC',
      display: 'inline'
    }
  })
);

export default useAutomationsStyles;
