import { Button, Typography } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import * as React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import usePunchListStyles from '../../styles/PunchList';

const PunchListMobile: React.FC = () => {
  const classes = usePunchListStyles();
  return (
    <>
      <Button variant='contained' fullWidth className={classes.addTask}>
        <Typography variant='button'>Add Task</Typography>
        <StaticImage
          width={24}
          layout='constrained'
          placeholder='tracedSVG'
          src='../../assets/TemplatePreview/new_task_outlined.png'
          alt='Add Task'
        />
      </Button>
      <Paper elevation={1} className={classes.punchCard}>
        <div className={classes.punchCardDiv}>
          <div>
            <div>
              <Typography variant='button'>Fix all defects</Typography>
            </div>
            <Typography variant='caption' color='textSecondary' noWrap>
              <span className={classes.statusDot} /> Not Started, High Priority
            </Typography>
            <div className={classes.punchAuthorDiv}>
              <StaticImage
                width={16}
                height={16}
                layout='fixed'
                placeholder='tracedSVG'
                src='../../assets/TemplatePreview/group_outlined.png'
                alt='Author'
              />
              <Typography variant='caption' color='textSecondary'>
                John Doe
              </Typography>
            </div>
          </div>
          <div>
            <StaticImage
              width={4}
              layout='constrained'
              placeholder='tracedSVG'
              src='../../assets/TemplatePreview/toggleMore.svg'
              alt='Add Task'
              style={{ verticalAlign: 'middle' }}
            />
          </div>
        </div>
      </Paper>
    </>
  );
};

export default PunchListMobile;
