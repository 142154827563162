import * as React from 'react';
import Table from '@material-ui/core/Table';
import Paper from '@material-ui/core/Paper';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { StaticImage } from 'gatsby-plugin-image';
import { TableFooter, Typography } from '@material-ui/core';
import FieldTitle from './FieldTitle';
import usePunchListStyles from '../../styles/PunchList';

const valueByColumnMap = {
  'Task Name': 'Fix all defects',
  Status: 'Not Started',
  Priority: 'High',
  Assignees: 'John Doe',
  '': ''
};

const title = 'Task(s) linked to this field (1)';

const PunchListWeb: React.FC = () => {
  const classes = usePunchListStyles();

  return (
    <>
      <FieldTitle name={title} isMandatory={false} />
      <Paper className={classes.tablePaper}>
        <TableContainer className={classes.tableContainer}>
          <Table>
            <TableHead className={classes.tableHeader}>
              <TableRow>
                {Object.keys(valueByColumnMap).map((column) => (
                  <TableCell key={column} align='left' classes={{ root: classes.tableCellRoot }}>
                    {column}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                {Object.values(valueByColumnMap).map((value) => (
                  <TableCell key={value} align='left' classes={{ root: classes.tableCellRoot }}>
                    {value === 'Not Started' ? <span className={classes.statusDot} /> : null}
                    {value}
                    {value === '' ? (
                      <StaticImage
                        width={4}
                        layout='constrained'
                        placeholder='tracedSVG'
                        src='../../assets/TemplatePreview/toggleMore.svg'
                        alt='Add Task'
                        style={{ verticalAlign: 'middle' }}
                      />
                    ) : null}
                  </TableCell>
                ))}
              </TableRow>
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell align='left' classes={{ root: classes.tableCellRoot }} colSpan={5}>
                  <div className={classes.tableFooterAction}>
                    <StaticImage
                      width={20}
                      layout='fixed'
                      placeholder='tracedSVG'
                      src='../../assets/TemplatePreview/new_task_outlined.png'
                      alt='Add Task'
                    />
                    <Typography variant='caption'>Add Task</Typography>
                  </div>
                </TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
};

export default PunchListWeb;
