import { createStyles, makeStyles } from '@material-ui/core/styles';

const useTableStyles = makeStyles(() =>
  createStyles({
    tablePaper: {
      boxShadow: 'unset'
    },
    tableRoot: {
      width: 'fit-content !important'
    },
    tableContainer: {
      maxHeight: 440,
      border: '1px solid rgba(224, 224, 224, 1)'
    },
    tableCellRoot: {
      verticalAlign: 'top',
      fontSize: '0.8rem'
    },
    tableRow: {
      paddingTop: '8px'
    },
    calculationRow: {
      lineHeight: '1.2rem',
      fontSize: '0.8rem',
      paddingBottom: '8px'
    },
    actionButtonContainer: {
      margin: '8px',
      padding: '4px',
      textAlign: 'left'
    },
    tableActionButtonTxt: {
      paddingLeft: '4px',
      color: '#00000061',
      fontSize: '0.8rem'
    },
    tableHeader: {
      backgroundColor: '#fafafa'
    },
    tableFooter: {
      backgroundColor: '#fafafa'
    }
  })
);

export default useTableStyles;
