import * as React from 'react';
import Paper from '@material-ui/core/Paper';
import SingleSelect from './SectionFields/SingleSelect';
import MultipleSelect from './SectionFields/MultipleSelect';
import Toggle from './SectionFields/Toggle';
import Signature from './SectionFields/Signature';
import Photo from './SectionFields/Photo';
import CheckBox from './SectionFields/Checkbox';
import Label from './SectionFields/Label';
import TextField from './SectionFields/TextField';
import DateField from './SectionFields/DateField';
import TimeField from './SectionFields/TimeField';
import TableField from './SectionFields/TableField';
import { SectionField, SectionFieldContainerProps } from '../typings';
import { FieldInputType } from '../utils/fieldInputType';
import Formula from './SectionFields/Formula';
import PunchListWeb from './SectionFields/PunchListWeb';
import PunchListMobile from './SectionFields/PunchListMobile';
import { matchesMobile } from '../utils/mediaQuery';

function renderFieldComponent(isMobile: boolean, sectionFieldDetails: SectionField, sectionRank: number, templateLanguage?: string) {
  const commonProps = { isMobile, sectionFieldDetails, sectionRank };
  switch (sectionFieldDetails.fieldInputType) {
    case FieldInputType.ShortText:
    case FieldInputType.LongText:
    case FieldInputType.NumberInteger:
    case FieldInputType.NumberDecimal:
      return <TextField {...commonProps} />;
    case FieldInputType.DateAndTime:
      return (
        <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '1rem' }}>
          <DateField {...commonProps} />
          <TimeField {...commonProps} />
        </div>
      );
    case FieldInputType.Date:
      return <DateField {...commonProps} />;
    case FieldInputType.Time:
      return <TimeField {...commonProps} />;
    case FieldInputType.MultiSelect:
      return <MultipleSelect {...commonProps} />;
    case FieldInputType.SingleSelect:
      return <SingleSelect {...commonProps} />;
    case FieldInputType.Toggle:
      return <Toggle {...commonProps} />;
    case FieldInputType.Signature:
      return <Signature {...commonProps} />;
    case FieldInputType.Photo:
      return <Photo {...commonProps} />;
    case FieldInputType.Checkbox:
      return <CheckBox {...commonProps} />;
    case FieldInputType.Label:
      return <Label {...commonProps} />;
    case FieldInputType.Formula:
      return <Formula {...commonProps} />;
    case FieldInputType.Table:
      return <TableField {...commonProps} templateLanguage={templateLanguage} />;
    default:
      return (
        <div>
          {sectionFieldDetails.name} - {sectionFieldDetails.fieldInputType}
        </div>
      );
  }
}

function renderPunchListTable(sectionFieldDetails: SectionField, isMobile: boolean) {
  const canCreateTask = sectionFieldDetails.extra?.canCreateTask ?? false;
  if (!canCreateTask) return null;
  return isMobile ? <PunchListMobile /> : <PunchListWeb />;
}

const SectionFieldContainer: React.FC<SectionFieldContainerProps> = (sectionFieldProps) => {
  const { isMobile, sectionFieldDetails, sectionRank, templateLanguage } = sectionFieldProps;
  const isLabel = sectionFieldDetails.fieldInputType === FieldInputType.Label;
  const isMobileDevice = matchesMobile();

  const fieldComponent = renderFieldComponent(isMobile, sectionFieldDetails, sectionRank, templateLanguage);
  const punchListComponent = renderPunchListTable(sectionFieldDetails, isMobile || isMobileDevice);
  const paddingVal = isMobile ? '0' : '1rem';

  return (
    <div style={{ paddingTop: paddingVal, paddingBottom: paddingVal }}>
      {isMobile && !isLabel && (
        <Paper style={{ padding: '0.8rem', margin: '6px' }}>
          {fieldComponent}
          {punchListComponent}
        </Paper>
      )}
      {isMobile && isLabel && <div style={{ padding: '8px 16px', margin: '4px 6px 0' }}>{fieldComponent}</div>}
      {!isMobile && (
        <>
          {fieldComponent}
          <div style={{ marginTop: '0.6rem' }}>{punchListComponent}</div>
        </>
      )}
    </div>
  );
};

export default SectionFieldContainer;
