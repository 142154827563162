import * as React from 'react';
import Typography from '@material-ui/core/Typography';
import { graphql, useStaticQuery } from 'gatsby';
import { LocationLanguageContextType, StrapiAutomationsPageQueryProps } from 'typings';
import { LocationLanguageContext } from '../../contexts/LocationLanguageContext';
import { Languages } from '../../utils/getAllSupportedLanguages';
import useAutomationsStyles from '../../styles/automationsStyle';

const strapiAutomationsPageQuery = graphql`
  query AutomationsPageQuery {
    allStrapiAutomationsPage {
      nodes {
        Title
        locale
      }
    }
  }
`;

interface AutomationsInfoProps {
  automationsCount: number;
}

const AutomationsInfo: React.FC<AutomationsInfoProps> = ({ automationsCount }) => {
  const data = useStaticQuery(strapiAutomationsPageQuery);
  const automationsPageContents: StrapiAutomationsPageQueryProps[] = data.allStrapiAutomationsPage.nodes;

  const locationLanguage = React.useContext<LocationLanguageContextType | null>(LocationLanguageContext);
  const language: string = locationLanguage?.language ?? Languages.English;
  const automationsPageContent = automationsPageContents.find((content) => content.locale === language);

  const automationsClasses = useAutomationsStyles();

  if (!automationsPageContent) return null;

  return (
    <>
      <Typography variant='h2' className={automationsClasses.header}>
        {automationsPageContent.Title}
        <Typography className={automationsClasses.countHeader}>{` ${automationsCount}`}</Typography>
      </Typography>
    </>
  );
};

export default AutomationsInfo;
