import { createStyles, makeStyles } from '@material-ui/core/styles';
import { globalColors } from './globalColorVariables';

const usePunchListStyles = makeStyles(() =>
  createStyles({
    statusDot: {
      width: '8px',
      height: '8px',
      backgroundColor: 'rgba(237, 110, 25, 1)',
      borderRadius: '50%',
      marginRight: '4px',
      display: 'inline-block'
    },
    /** Mobile Style */
    addTask: {
      backgroundColor: 'rgba(248, 248, 248, 1)',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      color: `${globalColors.primaryColor}`,
      padding: '0.7rem',
      margin: '0.6rem 0'
    },
    punchCard: {
      padding: '0.7rem'
    },
    punchCardDiv: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center'
    },
    punchAuthorDiv: {
      display: 'flex',
      gap: 10,
      alignItems: 'center'
    },
    /** End of Mobile Style */
    /** Web Style */
    tablePaper: {
      boxShadow: 'unset'
    },
    tableContainer: {
      maxHeight: 440,
      border: '1px solid rgba(224, 224, 224, 1)'
    },
    tableHeader: {
      backgroundColor: '#fafafa'
    },
    tableCellRoot: {
      verticalAlign: 'top',
      fontSize: '0.8rem',
      whiteSpace: 'nowrap'
    },
    tableFooterAction: {
      display: 'flex',
      gap: 5,
      alignItems: 'center',
      '& span': {
        color: `${globalColors.primaryColor}`
      }
    }
  })
);

export default usePunchListStyles;
