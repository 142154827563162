import * as React from 'react';
import { StrapiTemplateCategory, NovadeLiteTemplateInput, ScoreByRankMap, StrapiTemplatePageQueryProps } from 'typings';
import { Paper, Tab, Tabs } from '@material-ui/core';
import { TabPanel, TabContext } from '@material-ui/lab';
import DesktopFormContainer from '../DesktopFormContainer';
import TemplateWorkflowProvider from '../TemplateWorkflow';
import { Languages } from '../../utils/getAllSupportedLanguages';
import useWorkflowStyles from '../../styles/Workflow';
import AutomationsInfo from '../Automations/AutomationsInfo';

interface TemplateSectionProps {
  templateDetails: NovadeLiteTemplateInput;
  templateLanguage: StrapiTemplateCategory['name'] | Languages.English;
  templateMaxScoreMap: ScoreByRankMap;
  templatePageContent: StrapiTemplatePageQueryProps;
}

const TemplateSection: React.FC<TemplateSectionProps> = (props) => {
  const fieldTab = 'fields';
  const workflowTab = 'workflow';
  const automationsTab = 'automations';
  const { templateDetails, templateLanguage, templateMaxScoreMap, templatePageContent } = props;
  const { workflowSteps, workflowActions, automations } = templateDetails;
  const [tab, setTab] = React.useState<string>(fieldTab);

  const handleTabChange = (event: React.ChangeEvent<{}>, newTab: string) => {
    setTab(newTab);
  };
  const hasValidWorkflows = workflowSteps && workflowSteps?.length > 0 && workflowActions && workflowActions?.length > 0;
  const classes = useWorkflowStyles();
  const hasValidAutomations = automations && automations?.length > 0;

  return (
    <>
      <TabContext value={tab}>
        <Tabs
          value={tab}
          onChange={handleTabChange}
          aria-label='Templates Form Fields & Workflow'
          variant='standard'
          classes={{ indicator: classes.tabIndicator }}
          style={{
            borderTopLeftRadius: '4px'
          }}
        >
          <Tab
            label={templatePageContent.FormFieldsSection}
            value={fieldTab}
            classes={{ root: classes.tabRoot, selected: classes.selectedTab }}
          />
          {hasValidWorkflows ? (
            <Tab
              label={templatePageContent.WorkflowSection}
              value={workflowTab}
              classes={{ root: classes.tabRoot, selected: classes.selectedTab }}
            />
          ) : null}
          {hasValidAutomations ? (
            <Tab
              label={templatePageContent.AutomationsSection}
              value={automationsTab}
              classes={{ root: classes.tabRoot, selected: classes.selectedTab }}
            />
          ) : null}
        </Tabs>
        <Paper style={{ borderRadius: '0px 4px 4px 4px' }}>
          <TabPanel value={fieldTab}>
            <DesktopFormContainer
              templateDetails={templateDetails}
              templateLanguage={templateLanguage}
              templateMaxScoreMap={templateMaxScoreMap}
            />
          </TabPanel>
          {hasValidWorkflows ? (
            <TabPanel
              value={workflowTab}
              style={{
                overflow: 'visible',
                position: 'relative',
                height: '800px',
                width: '100%',
                padding: 0
              }}
            >
              <TemplateWorkflowProvider
                workflowSteps={workflowSteps}
                workflowActions={workflowActions}
                templatePageContent={templatePageContent}
              />
            </TabPanel>
          ) : null}
          {hasValidAutomations ? (
            <TabPanel value={automationsTab}>
              <AutomationsInfo automationsCount={automations.length} />
            </TabPanel>
          ) : null}
        </Paper>
      </TabContext>
    </>
  );
};

export default TemplateSection;
